$.lui("part",".part_tabs",function (selector) {
    selector.each(function () {
        let el = $(this);
        el.on("click",".elm_nav_link",function (e) {
            let link = $(this);
            if(link[0].hasAttribute("data-lib-anchor")){
                return;
            }
            link.closest(el).find(".state--active").removeClass("state--active");
            link.addClass("state--active");
            el.closest("[data-lib-anchor-offset]").anchor_anim(500);
            el.find(".wrp_part_body").animate({
                scrollLeft: link.closest("li")[0].offsetLeft - 48
            },250)
        })
    });
});