$.lui("layout","#layout_header", function(selector) {
    let refs = {
        searchArea: selector.find(`[data-ref="header-search"]`)
    };

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });

    (function(){
        let timeout;

        selector.on("click", `[data-toggle="header-search"]`, function() {
            clearTimeout(timeout);
            refs.searchArea.addClass("state--active").find("input").trigger("focus");
            body.addClass("body--no-scroll");
        });

        selector.on("click", `[data-click="header-search-hide"]`, function(e) {
            e.preventDefault();
            refs.searchArea.removeClass("state--active");
            body.removeClass("body--no-scroll");
        });
    })();
});

$.importScript(cdnjs.touchswipe, function() {
    $('.ssm-nav').lui_ssm();
});