$.lui("part", ".part_form_search", function(selector) {
    let refs = {
        autocompleteArea: selector.find(`[data-ref="autocomplete-area"]`)
    };

    let timeout;
    let request;

    $.fn.close = function() {
        let elm = $(this);
        elm.addClass("state--hiding");
        timeout = setTimeout(function() {
            elm.removeClass("state--active state--hiding");
        },300);
    };

    selector.on("input", `[data-input="search"]`, function() {
        clearTimeout(timeout);
        refs.autocompleteArea.removeClass("state--hiding");
        if(selector.find(`[data-input="search"]`).val() !== "") {
            selector.find(`[data-ref="autocomplete-area"]`).addClass("state--active");
            let input = selector.find('[data-input="search"]');

            if (request) {
                request.abort();
            }

            request = $.ajax({
                method: 'post',
                dataType: 'html',
                data: {do: 'search', q: input.val()}
            }).done(function (data) {
                refs.autocompleteArea.html(data);
            });
        }
        else {
            refs.autocompleteArea.close();
        }
    });

    doc.on("click", function (e) {
        if (selector.length && refs.autocompleteArea.hasClass("state--active")) {
            if ($(e.target).closest(selector).length === 0) {
                refs.autocompleteArea.close();
            }
        }
    });
});