(function($) {
    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_tilt", "[data-lib-tilt]", function(selector) {
        $.importScript(cdnjs.tilt,function () {
            selector.tilt({
                perspective: 1500
            });
        })
    });

    $.lui("lib_autosize", "[data-lib-autosize]", function(selector) {
        $.importScript(cdnjs.autosize,function () {
            autosize($(selector));
        });
    });

    $.lui("lib_scroll", "[data-scroll]", function(selector) {
        selector.on("click",function () {
            $(this).closest("[data-ref=\"scroll\"]").next("section, div").anchor_anim(500);
        });
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    setTimeout(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    },2500);
})(jQuery);